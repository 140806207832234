// ModalComponent.js
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { gql } from "@apollo/client";
import { client } from "../../../client";

import AWS from "aws-sdk";
import FileUploader from "./FileUploader";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const ModalContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white; /* Change to white background */
  padding: 20px;
  z-index: 100;
  border: 1px solid #ccc; /* Add a subtle border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; /* Center-align all children */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const UserInfoItem = styled.div`
  display: flex; /* Display children in a row */
  flex-direction: row;
  margin-bottom: 10px; /* Add margin between child components */
`;

const ModifyDocument = ({
  isOpen,
  data,
  handleCloseClick,
  handleSearchDocuType,
  currentInx,
  setModalOpen,
  children,
}) => {
  const [editableData, setEditableData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [selectedSampleFile, setSelectedSampleFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // TODO env 에 넣기
  const ACCESS_KEY = "AKIA2MB36EXBJXFEOVGR";
  const SECRET_ACCESS_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "seum-file";

  const closeClicked = () => {
    setSelectedFiles([]);
    setSelectedFiles2([]);
    handleCloseClick();
  };

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: "upload/" + file?.name,
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log("evt...", evt);
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          setShowAlert(true);
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading:", err);
            reject(err);
          } else {
            console.log("Upload successful:", data);
            const objectUrl = myBucket.getSignedUrl("getObject", {
              Key: params.Key,
            });

            const objectUrlString = objectUrl.split("?")[0];
            resolve(objectUrlString);
          }
        });
    });
  };
  const handleFileUploads = async () => {
    try {
      const pdfUrl =
        selectedFiles.length > 0 ? await uploadFile(selectedFiles[0]) : null;
      const hwpUrl =
        selectedFiles2.length > 0 ? await uploadFile(selectedFiles2[0]) : null;

      console.log("pdfUrl:", pdfUrl);
      console.log("hwpUrl:", hwpUrl);

      handleUpdateClick(pdfUrl, hwpUrl);
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  useEffect(() => {
    setEditableData({
      order: parseInt(data?.order) || null,
      docu_type1_id: data?.docu_type1_id || 1,
      docu_type2_id: data?.docu_type2_id || 1,
      name: data?.name || "",
      price: String(data?.price) || "",
      grade: data?.grade || "",
      year: parseInt(data?.year) || null,
      month_id: data?.month_id || null,
      subject: data?.subject || null,
      publisher_id: data?.publisher_id || null,
      category1_id: data?.category1_id || null,
      category2_id: data?.category2_id || null,
      pdfUrl: data?.pdfUrl || null,
      hwpUrl: data?.hwpUrl || null,
    });
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableData({ ...editableData, [name]: value });
  };

  const handleUpdateClick = (pdfUrl, hwpUrl) => {
    const MODIFY_DOCUMENT = gql`
      mutation modifyDocument(
        $id: Int
        $order: Int
        $docu_type1_id: Int
        $docu_type2_id: Int
        $name: String
        $price: String
        $grade: String
        $year: Int
        $month_id: Int
        $subject: String
        $publisher_id: Int
        $category1_id: Int
        $category2_id: Int
        $document_url_list: [document_url_input_v2]
      ) {
        modifyDocument(
          id: $id
          order: $order
          docu_type1_id: $docu_type1_id
          docu_type2_id: $docu_type2_id
          name: $name
          price: $price
          grade: $grade
          year: $year
          month_id: $month_id
          subject: $subject
          publisher_id: $publisher_id
          category1_id: $category1_id
          category2_id: $category2_id
          document_url_list: $document_url_list
        ) {
          status
        }
      }
    `;

    client
      .mutate({
        mutation: MODIFY_DOCUMENT,
        variables: {
          id: data.id,
          order: parseInt(editableData.order),
          docu_type1_id: editableData.docu_type1_id,
          docu_type2_id: editableData.docu_type2_id,
          name: editableData.name,
          price: editableData.price,
          grade: editableData.grade,
          year:
            editableData.year !== null && editableData.year !== undefined
              ? parseInt(editableData.year)
              : null,
          month_id: editableData.month_id,
          subject: editableData.subject,
          publisher_id: editableData.publisher_id,
          category1_id: editableData.category1_id,
          category2_id: editableData.category2_id,
          document_url_list: [
            { type: "PDF", object_url: pdfUrl },
            { type: "HWP", object_url: hwpUrl },
          ],
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log("Mutation successful:", res);
        alert("성공적으로 업로드했습니다.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyData = async (data) => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data));
      console.log("Data copied to clipboard:", data);
      alert("Data copied to clipboard: " + JSON.stringify(data));
    } catch (error) {
      console.error("Failed to copy data to clipboard:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <h2> 정보 수정 </h2>

      <Box maxWidth={"500px"} margin={"auto"}>
        <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
          <InputLabel id="docu_type1_id-label">대분류</InputLabel>
          <Select
            labelId="docu_type1_id-label"
            id="docu_type1_id"
            name="docu_type1_id"
            value={editableData.docu_type1_id}
            defaultValue={data.docu_type1_id}
            label="Document Type 1"
            onChange={handleInputChange}
            disabled
          >
            <MenuItem value={1}>모의고사/학평</MenuItem>
            <MenuItem value={2}>고등 부교재 (EBS 교재)</MenuItem>
            <MenuItem value={3}>수능연계교재 (수능특강, 수능완성)</MenuItem>
            <MenuItem value={4}>고등교과서</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
          <InputLabel id="docu_type2_id-label">중분류</InputLabel>
          <Select
            labelId="docu_type2_id-label"
            id="docu_type2_id"
            name="docu_type2_id"
            value={editableData.docu_type2_id}
            defaultValue={data.docu_type2_id}
            label="Document Type 2"
            onChange={handleInputChange}
          >
            <MenuItem value={1}>수업용자료</MenuItem>
            <MenuItem value={2}>워크북</MenuItem>
            <MenuItem value={3}>변형문제</MenuItem>
            <MenuItem value={4}>패키지</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
          <TextField
            label="자료명"
            name="name"
            value={editableData.name}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
          <TextField
            label="가격"
            name="price"
            value={editableData.price}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
          <TextField
            label="순서"
            name="order"
            value={editableData.order}
            onChange={handleInputChange}
          />
        </FormControl>

        {editableData.docu_type1_id === 1 ? (
          <div>
            <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
              <TextField
                label="학년"
                name="grade"
                value={editableData.grade}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
              <TextField
                label="시행 연도"
                name="year"
                value={editableData.year}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 1, width: "500px" }}>
              <TextField
                label="시행 월"
                name="month_id"
                value={editableData.month_id}
                onChange={handleInputChange}
              />
            </FormControl>
          </div>
        ) : null}

        {editableData.docu_type1_id == "2" ? (
          <div>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="docu_type2_id-label">분류 1</InputLabel>
              <Select
                labelId="docu_type2_id-label"
                id="category1_id"
                name="category1_id"
                value={editableData.category1_id}
                defaultValue={1}
                label="분류 1"
                onChange={handleInputChange}
              >
                <MenuItem value={1}>리딩파워</MenuItem>
                <MenuItem value={2}>올림포스</MenuItem>
                <MenuItem value={3}>수능특강 LIGHT</MenuItem>
                <MenuItem value={4}>올림포스 전국연합 기출 영어 독해</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="docu_type2_id-label">분류 2</InputLabel>
              {editableData.category1_id == 1 ? (
                <Select
                  labelId="docu_type2_id-label"
                  id="docu_type2category2_id_id"
                  name="category2_id"
                  value={editableData.category2_id}
                  defaultValue={1}
                  label="분류 2"
                  onChange={handleInputChange}
                >
                  <MenuItem value={1}>리딩파워 유형편 기본</MenuItem>
                  <MenuItem value={2}>리딩파워 유형편 완성</MenuItem>
                  <MenuItem value={3}>리딩파워 주제별독해완성</MenuItem>
                </Select>
              ) : editableData.category1_id == 2 ? (
                <Select
                  labelId="docu_type2_id-label"
                  id="docu_type2category2_id_id"
                  name="category2_id"
                  value={editableData.category2_id}
                  defaultValue={1}
                  label="분류 2"
                  onChange={handleInputChange}
                >
                  <MenuItem value={4}>올림포스1</MenuItem>
                  <MenuItem value={5}>올림포스2</MenuItem>
                </Select>
              ) : editableData.category1_id == 3 ? (
                <Select
                  labelId="docu_type2_id-label"
                  id="docu_type2category2_id_id"
                  name="category2_id"
                  value={editableData.category2_id}
                  defaultValue={1}
                  label="분류 2"
                  onChange={handleInputChange}
                >
                  <MenuItem value={6}>수능특강 LIGHT 영어편</MenuItem>
                  <MenuItem value={7}>수능특강 LIGHT 영어독해편</MenuItem>
                </Select>
              ) : editableData.category1_id == 4 ? (
                <Select
                  labelId="docu_type2_id-label"
                  id="docu_type2category2_id_id"
                  name="category2_id"
                  value={editableData.category2_id}
                  defaultValue={1}
                  label="분류 2"
                  onChange={handleInputChange}
                >
                  <MenuItem value={8}>고1</MenuItem>
                  <MenuItem value={9}>고2</MenuItem>
                </Select>
              ) : null}
            </FormControl>
          </div>
        ) : null}

        {editableData.docu_type1_id == "3" ? (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="docu_type2_id-label">분류 1</InputLabel>
            <Select
              labelId="docu_type2_id-label"
              id="category1_id"
              name="category1_id"
              value={editableData.category1_id}
              defaultValue={1}
              label="분류 1"
              onChange={handleInputChange}
            >
              <MenuItem value={5}>수능특강 영어</MenuItem>
              <MenuItem value={6}>수능특강 영어독해</MenuItem>
              <MenuItem value={7}>수능완성</MenuItem>
            </Select>
          </FormControl>
        ) : null}

        {editableData.docu_type1_id == "4" ? (
          <div>
            <TextField
              fullWidth
              label="과목"
              id="subject"
              name="subject"
              value={editableData.subject}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="docu_type2_id-label">출판사</InputLabel>
              <Select
                labelId="docu_type2_id-label"
                id="publisher_id"
                name="publisher_id"
                value={editableData.docu_type2_id}
                defaultValue={1}
                label="출판사"
                onChange={handleInputChange}
              >
                <MenuItem value={1}>교학사</MenuItem>
                <MenuItem value={2}>진학사</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : null}
        <div>
          <UserInfoItem>
            {["PDF", "HWP"].map((item) => {
              if (item == "PDF") {
                return (
                  <>
                    <div>pdf </div>
                    <FileUploader
                      setSelectedFiles={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      progress={progress}
                      setProgress={setProgress}
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <div>hwp </div>
                    <FileUploader
                      setSelectedFiles={setSelectedFiles2}
                      selectedFiles={selectedFiles2}
                      progress={progress}
                      setProgress={setProgress}
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                    />
                  </>
                );
              }
            })}
          </UserInfoItem>
          <div>
            {data.documentUrlList.length > 1 &&
            data.documentUrlList[1].object_url !== null ? (
              <button onClick={() => handleCopyData(data.hwpUrl)}>
                현재 HWP 파일 주소 복사
              </button>
            ) : null}
            {data.documentUrlList.length > 0 &&
            data.documentUrlList[0].object_url !== null ? (
              <button onClick={() => handleCopyData(data.pdfUrl)}>
                현재 PDF 파일 주소 복사
              </button>
            ) : null}
          </div>
        </div>
      </Box>
      <br />

      <Button variant="contained" onClick={() => handleFileUploads()}>
        저장
      </Button>

      <Button onClick={closeClicked}>닫기</Button>
    </ModalContainer>
  );
};

export default ModifyDocument;
