import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "../../client";
import {
  CREATE_ORDER,
  GET_PAYMENT_PRODUCTS, PURCHASE_PRODUCT,
} from "./gql/payment.gql";
import Checkbox from '@mui/material/Checkbox';



const PaymentProduct = () => {
  const accessToken = localStorage.getItem("accessToken");
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [productMembers, setProductMembers] = useState([]);
  const [productPoints, setProductPoints] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setProduct] = useState(null);

  const handleCheckboxChange = (productId) => {
    if([1,7,8].includes(productId)) { 
      alert('회원권 상품은 아직 준비중입니다.')
      return;
    }
    setSelectedProductId(productId);
  };

  const handleCheckBoxProductChange = (product) => {
    setProduct(product)
  }

  useEffect(() => {
    client
      .query({
        query: GET_PAYMENT_PRODUCTS,
        variables: {
          type: "MEMBER",
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log(res);
        setProductMembers(res.data.getPaymentProductList);
      })
      .catch((err) => {
        console.error("Error fetching payment products", err);
      });

    client
      .query({
        query: GET_PAYMENT_PRODUCTS,
        variables: {
          type: "POINT",
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log(res);
        setProductPoints(res.data.getPaymentProductList);
      })
      .catch((err) => {
        console.error("Error fetching payment products", err);
      });

    const { IMP } = window;
    IMP.init("imp71328801");
  }, []);

  // 아래 함수 외에는 없어야함
  // 1 createOrder (멤버십, 포인트 서버에서 알아서)
  const createOrder = async (productId, paymentMethod) => {
    if (!isLoggedIn) {
      alert("로그인을 먼저 진행해주세요");
      return;
    }
    await client
      .mutate({
        mutation: CREATE_ORDER,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          product_id: productId,
          payment_method: paymentMethod,
        },
      })
      .then((res) => {
        console.log(res.data.createOrder);
        //return paymentLogId
        // return res.data.createOrder;
        if (paymentMethod === "kakao") requestKakao(res.data.createOrder);
        else if (paymentMethod === "card") requestToss(res.data.createOrder);
      })
      .catch((err) => {
        console.error("Error creating order", err);
      });
  };

  // 2 startPayment (requestToss, requestNaver, requestKakao(=handlePayment) )
  const requestToss = (merchant_uid) => {
    const { IMP } = window;
    IMP.request_pay(
      {
        pg: `tosspayments.im_eipekskzh4`,
        pay_method: "card",
        merchant_uid: merchant_uid,
        name: selectedProduct.name,
        amount: selectedProduct.price,
        buyer_email: "",
        buyer_tel: "010-1234-5678",
        // m_redirect_url: "https://a-pex.co.kr/mypage",
        m_redirect_url: `https://a-pex.co.kr/mypage?merchant_uid=${merchant_uid}`,
                card: {
          useInstallment: false,
        },
        bypass: {
          expiredTime: new Date().getTime() + 1000 * 60 * 30,
          cashReceiptTradeOption: "CULTURE",
        },
      },
      (rsp) => {
        if (Object.keys(rsp).includes("error_code")) {
          alert(rsp.error_msg);
        } else {
          const imp_uid = rsp.imp_uid;
          completeOrder(parseInt(merchant_uid), imp_uid);
        }
        // if (rsp.success) {
        //   console.log("pg 결제가 완료되었습니다.");
        //   const imp_uid = rsp.imp_uid;
        //   return imp_uid; 
        // } else {
        //   console.log("pg 결제에 실패하였습니다.", rsp);
        //   return false;
        // }
      }
    );
  };
  const requestKakao = (merchant_uid) => {
    // completeOrder(merchant_uid, 3, docuInfo);
    const { IMP } = window;
    console.log("결제시작");
    IMP.request_pay(
      {
        pg: `kakaopay.CAXVTL0YKP`,
        pay_method: "card",
        merchant_uid: merchant_uid,
        name: selectedProduct.name,
        amount: selectedProduct.price,
        buyer_email: "",
        buyer_tel: "",
        // m_redirect_url: "https://a-pex.co.kr/mypage",
        m_redirect_url: `https://a-pex.co.kr/mypage?merchant_uid=${merchant_uid}`,
        // m_redirect_url: `http://172.20.10.3:3000/mypage?merchant_uid=${merchant_uid}`,
        card: {
          useInstallment: false,
        },
        bypass: {
          expiredTime: new Date().getTime() + 1000 * 60 * 30,
          cashReceiptTradeOption: "CULTURE",
        },
      },
      (rsp) => {
        if (rsp.success) {
          console.log(rsp);
          const imp_uid = rsp.imp_uid;
          completeOrder(parseInt(merchant_uid), imp_uid);
          // resolve(imp_uid); // 결제 성공
        } else {
          console.log("pg 결제에 실패하였습니다.", rsp);
          // resolve(false); // 결제 실패
        }
      }
      // {
      //   if (Object.keys(rsp).includes("error_code")) {
      //     alert(rsp.error_msg);
      //   } else {
      //     const imp_uid = rsp.imp_uid;
      //     completeOrder(parseInt(merchant_uid), imp_uid, true);
      //   }
      // }
    );
  };
  const startPayment = async (payMethod) => {
    if (payMethod === 1) {
      const paymentLogId = await createOrder(selectedProductId, "card");
      // completeOrder(paymentLogId, imp_uid, paymentResult);
    } else if (payMethod == 2) {
      const paymentLogId = await createOrder(selectedProductId, "kakao");
    } else {
      alert(
        "아직 지원하지 않는 결제 수단입니다. 신용카드 / 무통장입금을 이용해주세요."
      );
    }
  };

  // 3 completeOrder (PENDING 바꾸고, 로그 쌓고, user table 업데이트하고 등 일련의 과정)
  const completeOrder = async (paymentLogId, imp_uid) => {
    await client
      .mutate({
        mutation: PURCHASE_PRODUCT,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          payment_log_id: paymentLogId,
          imp_uid: imp_uid,
        },
      })
      .then((res) => {
        if(res.data.purchaseProduct == "success") {
          alert("결제 성공");
        } else {
          alert("결제 실패 : 결제가 취소되었습니다.");
        }
      })
      .catch((err) => {
        alert("결제 실패", err);
        console.error("Error completing order", err);
      });
  };
  return (
    <ProductWrapper>
      {/* {selectedProductId}*/}
      <ProductListWrapper>
        <ProductContainer>
          <ProductTitleContainer>
            <h2>회원권</h2>
          </ProductTitleContainer>
          <ProductContentsContainer>
            {productMembers.map((product) => (
              <ProductContent key={product.id}>
                <ProductContentTextBox>
                  <ProductContentText>{product.name}</ProductContentText>
                </ProductContentTextBox>

                <Checkbox
                  checked={selectedProductId === product.id}
                  onChange={() => {
                    handleCheckboxChange(product.id);
                    // handleCheckBoxProductChange(product);
                  }}
                />
              </ProductContent>
            ))}
          </ProductContentsContainer>
        </ProductContainer>

        <ProductContainer>
          <ProductTitleContainer>
            <h2>정액권</h2>
          </ProductTitleContainer>
          <ProductContentsContainer>
            {productPoints.map((product) => (
              <ProductContent key={product.id}>
                <ProductContentTextBox>
                  <ProductContentText>{product.name}</ProductContentText>
                </ProductContentTextBox>
                <Checkbox
                  checked={selectedProductId === product.id}
                  onChange={() => {
                    handleCheckboxChange(product.id);
                    handleCheckBoxProductChange(product);
                  }}
                />
              </ProductContent>
            ))}
          </ProductContentsContainer>
        </ProductContainer>
      </ProductListWrapper>
      <PayMethodContainer>
        <PayMethodItem onClick={() => startPayment(1)}>신용카드</PayMethodItem>
        <PayMethodItem onClick={() => startPayment(2)}>
          <img
            src="https://seum-file.s3.ap-northeast-2.amazonaws.com/upload/kakaopaybtn.svg"
            alt="카카오페이 로고"
            style={{ width: "120px", height: "100px" }}
          />
        </PayMethodItem>
        <PayMethodItem onClick={() => startPayment(3)}>네이버</PayMethodItem>
      </PayMethodContainer>
      <RefundContainer>
        <RefundContainer2>
          <RefundTitleContainer>환불규정</RefundTitleContainer>
          <RefundDescContainer>
            <li>
              무형 서비스의 서비스 제공 기간: 상품의 서비스 제공 기간은
              구매일로부터 1년입니다.
            </li>
            <li>
              환불 가능 기간: 충전권 및 정액권의 환불은 사용하지 않은 경우에만
              가능하며, 14일 이내에만 환불이 가능합니다.
            </li>
            <li>
              부분 환불: 충전권 또는 정액권 중 일부만 사용한 경우에도 사용하지
              않은 부분에 대한 부분 환불은 불가합니다.{" "}
            </li>
            <li>
              환불 처리 시간: 환불은 일반적으로 신청 후 영업일 기준 1-2일 이내에
              처리됩니다.{" "}
            </li>
            <li>
              환불 방법: 환불은 결제 하신 결제 수단의 취소 결제로 이루어지며,
              소요 시간은 카드사 별로 상이할 수 있습니다.
            </li>
          </RefundDescContainer>
        </RefundContainer2>
      </RefundContainer>
    </ProductWrapper>
  );
};

export default PaymentProduct;

const PayMethodItem= styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width : 100px;
  align-items: center;

  justify-content: center;
  
  bottom: 0px;
  border-radius: 4px;
  border: 1px solid rgb(202, 206, 224);
  background-color: rgb(255, 255, 255);
  color: var(--Gray-gray_500, #6a708a);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  width: 25%;
  height: 50px;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.accentHover};
    font-weight: 500;
    background: #0064FF;
    font-size: 1.2rem;
    color: #fff;
  }
`


const PayMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 720px;
  
  justify-content: space-evenly; /* Distribute items evenly

`

const RefundContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  width: 100%;

  justify-content: center; /* Distribute items evenly
  align-items:center;
`

const RefundContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 800px;
`

const RefundTitleContainer = styled.div`
  display: flex;
  color: gray;
  text-align: left;
  margin-bottom: 1rem;
  font-size:  ${(props) => props.theme.fontSize.subtitle};
`

const RefundDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: gray;
  text-align: left;
  gap: 4px;
  font-size:  ${(props) => props.theme.fontSize.subtitle};
`

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const ProductContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  width: 100%;
  height: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductTitleContainer = styled.div`
  background-color: #f7f7f7;
  border-bottom: 1px solid #eee;
  padding: 5px;
`;

const ProductContentsContainer = styled.div`
  padding: 10px;
`;

const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #f0f0f0;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ProductContentTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductContentText = styled.span`
  font-size: 16px;
`;
