// SidebarMenu.js

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { client } from '../../../client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import UserModal from './UserModal';

// DataGrid

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, InputBase } from '@mui/material';

const GET_USER_LIST = gql`
  query getUserListByAdmin(
    $id: Int
    $name: String
    $email: String
    $phone_number: String
    $type: Type
    $region1: String
    $region2: String
    $page: Int
    $pageSize: Int
  ) {
    getUserListByAdmin(
      id: $id
      name: $name
      email: $email
      phone_number: $phone_number
      type: $type
      region1: $region1
      region2: $region2
      page: $page
      pageSize: $pageSize
    ) {
      id
      name
      email
      phone_number
      type
      region1
      region2
      ad_agreement
      current_point
      created_at
    }
  }
`;

const UserPage = () => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchData = async () => {
    await client
      .query({
        query: GET_USER_LIST,
        variables: {
          page: 1,
          pageSize: 100000,
          // Add other variables as needed
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        console.log(res);
        console.log("refetchData...", res);
        setUserList(res.data.getUserListByAdmin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const userList = data.getUserListByAdmin;
  const handleEditClick = (user) => {
    setSelectedUser(user);
    setModalOpen(!isModalOpen);
  };

  const handleCloseClick = () => {
    setModalOpen(false);
  };

  // DataGrid Columns

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'isAdmin', headerName: '관리자', flex: 1 },
    { field: 'name', headerName: '이름', flex: 1 },
    { field: 'email', headerName: '이메일', flex: 1 },
    { field: 'phone_number', headerName: '핸드폰번호', flex: 1 },
    { field: 'type', headerName: '유형', flex: 1 },
    { field: 'region1', headerName: '지역1', flex: 1 },
    { field: 'region2', headerName: '지역2', flex: 1 },
    { field: 'current_point', headerName: '잔액', flex: 1 },
    {
      field: 'created_at',
      headerName: '가입일',
      flex: 1,
      renderCell: (params) => {
        params = parseInt(params.value);
        return (
          <Box whiteSpace={'normal'}>
            {new Date(params).toLocaleString('ko-kr')}
          </Box>
        );
      },
    },
    {
      field: 'edit',
      headerName: '정보수정',
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => handleEditClick(params.row)}>수정</Button>
      ),
    },
  ];

  return (
    <div>
      <Box
        margin={'7px'}
        display={'flex'}
        height={'70px'}
        justifyContent={'space-between'}
      >
        <Box ml={'5px'}>
          <h2>유저관리</h2>
        </Box>
      </Box>
      <Box>
        <DataGrid
          rows={userList}
          columns={columns}
          pageSize={5}
          components={{
            Toolbar: () => <GridToolbar />,
          }}
        />
      </Box>

      {/* <h2>유저 관리</h2>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell align="right">관리자</TableCell>
            <TableCell align="right">이름</TableCell>
            <TableCell align="right">이메일</TableCell>
            <TableCell align="right">핸드폰번호</TableCell>
            <TableCell align="right">유형</TableCell>
            <TableCell align="right">지역1</TableCell>
            <TableCell align="right">지역2</TableCell>
            <TableCell align="right">잔액</TableCell>
            <TableCell align="right">가입일</TableCell>
            <TableCell align="right">정보수정</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.isAdmin}
              </TableCell>
              <TableCell align="right">{user.name}</TableCell>
              <TableCell align="right">{user.email}</TableCell>
              <TableCell align="right">{user.phone_number}</TableCell>
              <TableCell align="right">{user.type}</TableCell>
              <TableCell align="right">{user.region1}</TableCell>
              <TableCell align="right">{user.region2}</TableCell>
              <TableCell align="right">{user.current_point}</TableCell>
              <TableCell align="right">{user.created_at}</TableCell>
              <TableCell align="right">
                <Button onClick={() => handleEditClick(user)}>수정</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}

      {/* Modal for Edit */}
      <UserModal
        isOpen={isModalOpen}
        handleCloseClick={handleCloseClick}
        refetchParentData={fetchData}
        data={selectedUser}
      ></UserModal>
    </div>
  );
};

export default UserPage;
