import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Pagination,
} from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import FileDownloader from "../../document/download/FileDownloader";
import SidebarMyPage from "./SideBar";
import MyPageMembership from "./MyPageMembership";
import MyPagePendingPayment from "./MyPagePendingPayment";

import { GET_PAYMENT_INFO, GET_PAYMENT_INFO_V2 } from "../gql/mypage.gql";
import { client } from "../../../client";
import { JWTDecoder } from "../../customer/style/Customer.module";
const MyPagePayment = () => {
  const [paymentLogs, setPaymentLogs] = useState([]);
  const navigate = useNavigate();

  const userId = JWTDecoder();

  useEffect(() => {
    client
      .query({
        query: GET_PAYMENT_INFO_V2,
        variables: {
          user_id: userId,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setPaymentLogs(res.data.getPaymentInfoByMypageV2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const modifyDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(parseInt(date)).toLocaleString("ko-KR", options);
  };

  const handleStatus = (status) => {
    if (status === "PENDING") {
      return <Box>입금확인중</Box>;
    } else if (status === "COMPLETE") {
      return <Box>결제완료</Box>;
    }
  };

  const handleMethod = (method) => {
    if (method === "KAKAO" || method === "kakao") {
      return <Box>카카오페이</Box>;
    } else if (method === "TOSS" || method === "card") {
      return <Box>신용카드</Box>;
    } else if (method === "cash") {
      return <Box>무통장입금</Box>;
    } else if (method === "POINT") {
      return <Box>포인트</Box>;
    } else if (method === "MEMBER") {
      return <Box>멤버십</Box>;
    }
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Wrapper>
        <TitleContainer>
          <h3>내정보</h3>
        </TitleContainer>
        <MyPageMembership />
        {/* {paymentLogs.length}
        {JSON.stringify(paymentLogs.slice(0, 5))} */}
        <TitleContainer>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ display: "inline", marginRight: "10px" }}>
              무통장입금
            </h3>
            <div
              style={{
                display: "inline-block",
                textAlign: "left",
                color: "blue",
              }}
            >
              <div></div>
              <div>신한은행 100-036-874700 / (주)에이펙스 김동재</div>
            </div>
          </div>
        </TitleContainer>
        <br />
        {/* <div>
          <RedText>
            토요일 오후 5시 이후부터는 무통장입금 승인이 불가합니다.{" "}
          </RedText>
          (월요일 오전부터 순차적으로 승인)
          <br />
          <RedText>급히 자료가 필요하시다면,</RedText> 무통장입금 운영시간과
          상관없이 <BlueText>다른 결제수단을</BlueText> 이용해주시길 바랍니다.
          <br />
          무통장 입금 신청 완료 후{" "}
          <RedText>해당 계좌로 입금만 하지 않으시면</RedText> 다른 결제 수단으로
          변경하셔도 <RedText>중복으로 결제 처리 되지 않습니다.</RedText>
        </div> */}
        <MyPagePendingPayment />
        <TitleContainer>
          <h3>포인트 사용내역</h3>
        </TitleContainer>
        <MyPageContainer>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>결제일</TableCell>
                  <TableCell style={{ textAlign: "center" }}>상태</TableCell>
                  <TableCell>포인트 금액</TableCell>
                  <TableCell>결제 금액</TableCell>
                  <TableCell>결제 수단</TableCell>
                  <TableCell>상품</TableCell>
                  <TableCell style={{ textAlign: "center", width: "100px" }}>
                    다운로드
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentLogs.map((paylog, index) => {
                  const paymentProductName =
                    paylog?.payment_log?.payment_product?.name;
                  const documentName = paylog?.document?.name;

                  // 다운로드 버튼 노출 로직
                  const previousPaylog = paymentLogs[index - 1];
                  const previousPaymentProductName =
                    previousPaylog?.payment_log?.payment_product?.name;
                  const previousDocumentName = previousPaylog?.document?.name;
                  const previousDocumentStatus = previousPaylog?.status;

                  const isSameProductAsPrevious =
                    paylog.status == "USE" &&
                    previousDocumentStatus == "USE" &&
                    previousDocumentName === documentName;

                  // if (index < 3) {
                  //   console.log("\nindex", index);
                  //   console.log("\n\npaylog", paylog);
                  //   console.log("pre", previousPaylog);
                  //   console.log(
                  //     "SameProductAsPrevious",
                  //     isSameProductAsPrevious
                  //   );
                  // }

                  return (
                    <TableRow key={paylog.id} style={{ height: "30px" }}>
                      <TableCell>
                        <Box>{modifyDate(paylog.created_at)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{paylog.status == "USE" ? "사용" : "충전"}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{paylog.point}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {paylog.status == "SAVE"
                            ? paylog?.payment_log?.amount
                            : null}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {paylog.status == "SAVE"
                            ? paylog?.payment_log?.payment_method
                            : null}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {documentName != null
                            ? documentName
                            : paymentProductName}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            height: "100%",
                            gap: "10px",
                          }}
                        >
                          {paylog.status == "USE" &&
                            !isSameProductAsPrevious &&
                            paylog?.document?.documentUrlList.map((item) => {
                              let iconPath = "/images/pdf-icon.png";
                              if (item?.type == "HWP") {
                                iconPath = "/images/hwp-icon.jpeg";
                              }
                              return (
                                <FileDownloader
                                  url={item?.object_url}
                                  documentId={paylog?.document?.id}
                                  point={paylog?.document?.price}
                                  isRedownload={true}
                                >
                                  <img src={iconPath} alt=" 다운로드" />
                                </FileDownloader>
                              );
                            })}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </MyPageContainer>
      </Wrapper>
    </Box>
  );
};

const RedText = styled.span`
  color: red; /* 빨간색 텍스트 색상 */
`;

const BlueText = styled.span`
  color: blue; /* 빨간색 텍스트 색상 */
`;

const Wrapper = styled.div`
  width: 1000px;
`;

const MyPageContainer = styled.div`
  margin: 20px;
  border: 1px solid black;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 1.2rem;
`;

export default MyPagePayment;
